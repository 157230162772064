.desktop {
  text-align: left;
  /* margin: 16px; */
  /* padding: 16px; */
  border-radius: 6px;
  /* border-color: white;
  border-width: 2px;
  border-style: solid; */
  text-align: center;
}

.mobile {
  text-align: left;
  margin: 12px;
  padding: 12px;
  border-radius: 6px;
  /* border-color: white;
  border-width: 2px;
  border-style: solid; */
  text-align: center;
}

a, a:visited {
  color: rgb(255, 213, 27);
  text-decoration: none;
}

ul {
  padding-left: 20px;
}

ul.normal {
  padding-left: 54px;
}

ol {
  padding-left: 20px;
}

ol.normal {
  padding-left: 54px;
}

.center {
  text-align: center;
}

.smallerWidth {
  width: 100%
}